import React, { useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Features from "./components/Features/Features";
import Humanized from "./components/Humanized/Humanized";
import Upcoming from "./components/Upcoming/Upcoming";
import LegendVideo from "./components/LegendVideo/LegendVideo";
import Everything from "./components/Everything/Everything";
import LegendReport from "./components/LegendReport/LegendReport";
import Capabilities from "./components/Capabilities/Capabilities";
import ProdutEco from "./components/ProdutEco/ProdutEco";
import Frequently from "./components/Frequently/Frequently";
import Footer from "./components/Footer/Footer";
import ChatWidget from "./components/ChatWidget/ChatWidget";
import BannerBlue from "./components/BannerBlue/BannerBlue";
import HomePage from "./pages/HomePage";
import Leadership from "./pages/Leadership";
import PlatformsPage from "./pages/PlatformsPage";
import WhitepaperPage from "./pages/WhitepaperPage";
import ContactPage from "./pages/ContactPage";

const App = () => {
  return (
   
     <Routes>
        <Route  path="/" element={<HomePage/>} />
        <Route  path="/leadership" element={<Leadership />} />
        <Route  path="/platforms" element={<PlatformsPage />} />
        <Route  path="/whitepaper" element={<WhitepaperPage />} />
        <Route  path="/contact" element={<ContactPage />} />
     
      </Routes>
    
  );
};

export default App;
