import React, { useState } from 'react'
import "../ContactCard/ContactCard.css";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from 'react-input-mask';




const ContactCard = () => {


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');
    const [contact, setContact] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [msgError, setMsgError] = useState('');
    const [contactError, setContactError] = useState('');
  
    const validateForm = () => {
      let isValid = true;
  
      if (name.trim() === '') {
        setNameError('Please enter your name.');
        isValid = false;
      } else {
        setNameError('');
      }
  
      if (email.trim() === '' || !validateEmail(email)) {
        setEmailError('Please enter a valid email.');
        isValid = false;
      } else {
        setEmailError('');
      }
  
      if (msg.trim() === '') {
        setMsgError('Please enter your message.');
        isValid = false;
      } else {
        setMsgError('');
      }
  
      if (contact.trim() === '' || !validateContact(contact)) {
        setContactError('Please enter a valid contact number.');
        isValid = false;
      } else {
        setContactError('');
      }
  
      return isValid;
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (validateForm()) {
        console.log('Form submitted successfully.');
      }
    };
  
    const validateEmail = (email) => {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    };
  
    const validateContact = (contact) => {
      // Validate if the contact contains only numbers and the plus sign
      const re = /^\+?\d+$/;
      return re.test(contact);
    };
  
    const handleNameChange = (value) => {
      setName(value);
      if (value.trim() !== '') {
        setNameError('');
      }
    };
  
    const handleEmailChange = (value) => {
      setEmail(value);
      if (value.trim() !== '' && validateEmail(value)) {
        setEmailError('');
      }
    };
  
    const handleMsgChange = (value) => {
      setMsg(value);
      if (value.trim() !== '') {
        setMsgError('');
      }
    };
  
    const handleContactChange = (value) => {
      setContact(value);
      if (value.trim() !== '' && validateContact(value)) {
        setContactError('');
      }
    };
  

    return (
        <div className='page-limitation-wrapper'>
            <div className="page-limitation">
                <div className="top-conatiner">
                    <h1>Contact</h1>
                    <div className="text-center">
                        <div className="contact-us-bootom-text">
                        </div>
                    </div>
                </div>
                <div className="mid-container">
                    <div className="form-card-container">
                        {/* <form method="POST" action="http://httpbin.org/post" accept-charset="UTF-8" className="form-inner-container"><input name="_token" type="hidden" value="VrO4XyUzmXFMx5hfliM7nNy8WJL509LjpfwwNqFZ" />
                            <div className="form-conatiner">
                                <input
                                    className="input-conatiner"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                />
                                <div className="error-message">{nameError}</div>
                            </div>
                            <div className="form-conatiner">
                                <input
                                    className="input-conatiner"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                />
                                <div className="error-message">{emailError}</div>
                            </div>

                            <div className="form-conatiner">
                                <input className="input-conatiner" placeholder="Company" name="company" type="text" />
                            </div>
                            <div className="form-conatiner">
                                <select className="input-conatiner" id="country" name="country">
                                    <option value="United States">United States</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Antigua &amp; Deps">Antigua &amp; Deps</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bosnia Herzegovina">Bosnia Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="Brunei">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina">Burkina</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Central African Rep">Central African Rep</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Congo {Democratic Rep}">Congo Democratic Rep</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="East Timor">East Timor</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland {Republic}">Ireland Republic</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Ivory Coast">Ivory Coast</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea North">Korea North</option>
                                    <option value="Korea South">Korea South</option>
                                    <option value="Kosovo">Kosovo</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Laos">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia">Micronesia</option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar, {Burma}">Myanmar, Burma</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">Russian Federation</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="St Kitts &amp; Nevis">St Kitts &amp; Nevis</option>
                                    <option value="St Lucia">St Lucia</option>
                                    <option value="Saint Vincent &amp; the Grenadines">Saint Vincent &amp; the Grenadines</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome &amp; Principe">Sao Tome &amp; Principe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Sudan">South Sudan</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syria">Syria</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad &amp; Tobago">Trinidad &amp; Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Vatican City">Vatican City</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Vietnam</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                            </div>
                            <div className="form-conatiner">
                                <input className="input-conatiner" placeholder="Contact No" name="contact" type="text" />
                            </div>

                            <div className="form-conatiner">
                                <textarea
                                    className="input-conatiner"
                                    placeholder="Message"
                                    value={msg}
                                    onChange={(e) => setMsg(e.target.value)}
                                    cols="40"
                                    rows="8"
                                ></textarea>
                                <div className="error-message">{msgError}</div>
                            </div>

                            <div className="form-conatiner">
                                <label className="col-md-3 col-form-label text-md-right"></label>
                                <div className="col-md-6">

                                    <ReCAPTCHA
                                        sitekey="6LflhJgpAAAAAG_TkmVx7i13s0NoZQwp-WZCxfR-"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="form-btn-group form-conatiner">
                                <input className="form-submit" type="submit" value="Send" />
                            </div>

                        </form> */}
                       <form method="POST" action="http://httpbin.org/post" acceptCharset="UTF-8" className="form-inner-container" onSubmit={handleSubmit}>
      <div className="form-conatiner">
        <input
          className="input-conatiner"
          placeholder="Name"
          value={name}
          onChange={(e) => handleNameChange(e.target.value)}
          type="text"
        />
        <div className="error-message">{nameError}</div>
      </div>

      <div className="form-conatiner">
        <input
          className="input-conatiner"
          placeholder="Email"
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
          type="email"
        />
        <div className="error-message">{emailError}</div>
      </div>

      <div className="form-conatiner">
        <input className="input-conatiner" placeholder="Company" name="company" type="text" />
      </div>

      <div className="form-conatiner">
        <select className="input-conatiner" id="country" name="country">
        <option value="United States">United States</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Antigua &amp; Deps">Antigua &amp; Deps</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bosnia Herzegovina">Bosnia Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="Brunei">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina">Burkina</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Central African Rep">Central African Rep</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Congo {Democratic Rep}">Congo Democratic Rep</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="East Timor">East Timor</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland {Republic}">Ireland Republic</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Ivory Coast">Ivory Coast</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea North">Korea North</option>
                                    <option value="Korea South">Korea South</option>
                                    <option value="Kosovo">Kosovo</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Laos">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia">Micronesia</option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar, {Burma}">Myanmar, Burma</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">Russian Federation</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="St Kitts &amp; Nevis">St Kitts &amp; Nevis</option>
                                    <option value="St Lucia">St Lucia</option>
                                    <option value="Saint Vincent &amp; the Grenadines">Saint Vincent &amp; the Grenadines</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome &amp; Principe">Sao Tome &amp; Principe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Sudan">South Sudan</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syria">Syria</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad &amp; Tobago">Trinidad &amp; Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Vatican City">Vatican City</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Vietnam</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
        </select>
      </div>

      <div className="form-conatiner">
        <InputMask
          mask="99999999999999"
          maskChar=""
          className="input-conatiner"
          placeholder="Contact No"
          value={contact}
          onChange={(e) => handleContactChange(e.target.value)}
        />
        <div className="error-message">{contactError}</div>
      </div>

      <div className="form-conatiner ">
        <textarea
          className="input-conatiner"
          placeholder="Message"
          value={msg}
          onChange={(e) => handleMsgChange(e.target.value)}
          cols="40"
          rows="8"
        ></textarea>
        <div className="error-message">{msgError}</div>
      </div>

      <div className="form-conatiner">
        <label className="col-md-3 col-form-label text-md-right"></label>
        <div className="col-md-6">
          <ReCAPTCHA
            sitekey="6LflhJgpAAAAAG_TkmVx7i13s0NoZQwp-WZCxfR-"
            onChange={(value) => console.log(value)} // You can handle captcha change event here
          />
        </div>
      </div>

      <div className="form-btn-group form-conatiner">
        <input className="form-submit" type="submit" value="Send" />
      </div>
    </form>
                    </div>


                    <div className="contact-details-wrapper">
                        <div className="contact-details-container">
                            <div className="contact-details-inner">
                                <div className="contact-details-items">
                                    <div className="contact-details-item">
                                        <div className="contact-details-item-inner">
                                            <div className="contact-details-item-mob-icon">
                                                <img src="https://dejavu.quest/img/ContactPage/call_icon.png" alt="" />
                                            </div>
                                            <div className="contact-details-content">
                                                <div className="contact-details-item-mob-text">
                                                    Click to call
                                                </div>
                                                <div className="contact-details-item-mob-number">
                                                    <a href="tel:++19543572326">+1 (954) 357 2326</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contact-details-item address-item">
                                        <div className="contact-details-item-inner">
                                            <div className="contact-details-address-content">
                                                <div className="contact-details-item-add-icon">
                                                    <img src="https://dejavu.quest/img/ContactPage/usa_flag.jpg" alt="" />
                                                </div>
                                                <div className="contact-details-item-add-text">
                                                    One Financial Plaza <br />100 SE 3rd Avenue, Suite 1000,<br />10th Floor, Fort Lauderdale FL 33394
                                                </div>
                                            </div>
                                        </div>
                                        <div className="contact-details-item-inner">
                                            <div className="contact-details-address-content">
                                                <div className="contact-details-item-add-icon">
                                                    <img src="https://dejavu.quest/img/ContactPage/usa_flag.jpg" alt="" />
                                                </div>
                                                <div className="contact-details-item-add-text">
                                                    651N Broad Street,<br />Suite 206,  <br />Middletown, DE 19709
                                                </div>
                                            </div>
                                        </div>
                                        <div className="contact-details-item-inner">
                                            <div className="contact-details-address-content">
                                                <div className="contact-details-item-add-icon">
                                                    <img src="https://dejavu.quest/img/ContactPage/sl_flag.jpg" alt="" />
                                                </div>
                                                <div className="contact-details-item-add-text">
                                                    Level 26, East Tower,<br /> World Trade Center, <br /> Echelon Square, Colombo 01
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="contact-details-item">
                                        <div className="contact-details-item-inner">
                                            <div className="contact-details-item-add-icon">
                                                <img src="https://dejavu.quest/img/ContactPage/email_icon.png" alt="" />
                                            </div>
                                            <div className="contact-details-content">
                                                <div className="contact-details-item-mob-text">
                                                    Write to us at
                                                </div>
                                                <div className="contact-details-item-add-text">
                                                    <a href="mailto:info@dejavu.quest">info@dejavu.quest</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="contact-us-bootom-text">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactCard