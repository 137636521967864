import React from 'react'
import "../Navbar/Navbar.css";
import main_banner_video from "../../video/main_banner_video.mp4";
import main_bg_banner_video from "../../video/knightingale_main_banner.mp4";
import knightingale_main_banner_mobile from "../../video/knightingale_main_banner_mobile.mp4";
import knightingale_logo from "../../img/knightingale_logo.png";
import menu from "../../img/menu.svg";
import knightingale_agents_main_banner from "../../img/Navabar/main_banner_video_thumbnail.jpg";
import knightingale_main_banner_mobile_thumbnail from "../../img/Navabar/knightingale_main_banner_mobile_thumbnail.jpg";
import knightingale_main_banner_thumbnail from "../../img/Navabar/knightingale_main_banner_thumbnail.jpg";

const MainBanner = () => {
  const handleClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSejhBcGyhl3O7adKyeOxXgPKKp01oqeJ2nVaknv5T5KemlSwQ/viewform?usp=sf_link', '_blank');
  };

  const askClick = () => {
    window.open('https://ask.knightingale.ai/', '_blank');
  };

  return (
    <>
      <div className="main-banner-wrapper">
        <div className="main-banner-container">
            <div className='video-container'>
              <video poster={knightingale_main_banner_thumbnail} src={main_bg_banner_video}  playsinline loop autoPlay muted  />
          </div>
          <div className="main-banner-inner">
            <div className="banner-container banner-container-left-side">
              <div className="banner-inner  banner-container-left">
                <div className="banner-header">Hello, I’m Knightingale,</div>
                <div className="banner-header-2">Your AI-Powered</div>

                <div className="banner-title">Wellness Buddy</div>
                <div className="banner-text">
                  My life-mission is to make you healthier and your day brighter.
                  Ready to embark on our wellness journey together?
                </div>
                <div className="banner-wrapper-btn">
                  <div className="banner-inner-btn">
                    {/* <button className="banner-btn banner-btn-2" onClick={askClick} >Ask Knightingale</button> */}
                    <button className="banner-btn  banner-btn-1" onClick={handleClick}>Get Early Access</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner-container  banner-container-right-side">
              <div className="banner-inner  banner-container-right">
                <div className="banner-inner-img">
                  <video width="100%" height="100%" style={{ objectFit: 'cover' }}poster={knightingale_main_banner_mobile_thumbnail} src={knightingale_main_banner_mobile} playsinline loop autoPlay muted />

                  {/* <img style={{width:"100%", height:"100%"}} src={knightingale_agents_main_banner} alt=''/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainBanner