import React from "react";
import everythingImg from "../../img/Everything/everythiing.png";
import done from "../../img/Everything/done.png";
import a_graphic from "../../img/Everything/1_graphic.png";
import b_graphic from "../../img/Everything/2_graphic.png";
import c_graphic from "../../img/Everything/3_graphic.png";
import knightingale_chat_mobile from "../../img/Everything/knightingale_chat_mobile.jpg";
import d_graphic from "../../img/Everything/4_graphic.png";
import mobile_video_loop_thumbnail from "../../img/Everything/mobile_video_loop_thumbnail.jpg";
import mobile_video_loop from "../../video/mobile_video_loop.mp4";
import mobile_video_mobile_loop from "../../video/mobile_video_mobile_loop.mp4";

import "../Everything/Everything.css";

const Everything = () => {
  return (
    <div className="kn-section-wrpper">
      <div className="kn-section-container kn-section-container-everything ">
        <div className="kn-section-inner kn-section-inner-evrything">
          <div className="kn-section-title"></div>
          <div className="everything-wrapper">
            
            <div className="everything-video-container">
              <div className="everything-video-inner">
                <div className="everything-video">
                  {/* <video width="100%" height="100%" playsinline loop autoPlay muted poster={mobile_video_loop_thumbnail} className="desktop_vedio" >
                    <source src={mobile_video_loop} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <video width="100%" height="100%" playsinline loop autoPlay muted poster={mobile_video_loop_thumbnail} className="mobile_vedio" >
                    <source src={mobile_video_mobile_loop} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> */}
                      <img src={knightingale_chat_mobile} alt="done"  style={{width:'100%'}}/>
                </div>
              </div>
{/* <div className="everything-video-circle"></div> */}

            </div>
            <div className="everything-details">
              <div className="everyting-detail-title">
              Always by your side..
                </div>
            
              <div className="everyting-detail-points">
                
                <ul className="everyting-detail-points-ul">
                                   <li className="everyting-detail-points-wrapper">
                    
                    <div className="everyting-detail-point">
                     <div  className="everyting-detail-point-name"><img src={a_graphic}/>I'm Here 24/7:</div> 
                     <div  className="everyting-detail-point-details"> I’ll be there around the clock, offering support through instant messaging and voice.</div> 
                    </div>
                  </li>
                  <li className="everyting-detail-points-wrapper">
                    
                    <div className="everyting-detail-point">
                    <div  className="everyting-detail-point-name"><img src={b_graphic}/>I'm Personalized:</div> 
                     <div  className="everyting-detail-point-details"> I'll ask insightful questions and listen attentively to your concerns.</div> 
                    </div>
                  </li>
                  <li className="everyting-detail-points-wrapper">
                    
                    <div className="everyting-detail-point">
                    <div  className="everyting-detail-point-name"><img src={c_graphic}/>I'm a Health Guru:</div> 
                     <div  className="everyting-detail-point-details">I bring you a wealth of knowledge in health and wellness, ready to guide you.</div> 
                    </div>
                  </li>
                  <li className="everyting-detail-points-wrapper">
                    
                    <div className="everyting-detail-point">
                    <div  className="everyting-detail-point-name"><img src={d_graphic}/>I'll Be Your BFF:</div> 
                     <div  className="everyting-detail-point-details">Let's form a special bond! I'm here to learn about you, providing personalized support.</div> 
                    </div>
                  </li>
                  
                </ul>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Everything;
