import React, { useState } from "react";
import footer_logo from "../../img/footer-logo.png";
import knightingale_logo from "../../img/knightingale_logo.png";

// import calorie_counter from "../../img/ProdutEco/calorie-counter.png";
// import care_remainder from "../../img/ProdutEco/care-reminder.png";

import "../Footer/Footer.css";
import CookiePolicy from "../CookiePolicy/CookiePolicy";

const Footer = () => {
   
 
   
    return (
        <div className="kn-section-wrpper">
            <div className="kn-section-container kn-section-container-Footer ">
                <div className="kn-section-inner kn-section-inner-Footer">


                    <div className="Footer-wrapper">
                        <div className="Footer-container">
                            <div className="Footer-container-logo">
                                <div className="Footer-container-logo-img">
                                <img src={knightingale_logo} alt="knightingale" />

                                </div>
                                {/* <div className="Footer-container-img-details">
                                    © 2024 Knightingale, Inc.
                                </div> */}
                            </div>
                            <div className="Footer-container-site">
                            © 2024 Knightingale, Inc.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          

        </div>
    );
};

export default Footer;
