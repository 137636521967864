import React from "react";
import knightingale_blue from "../../img/ProdutEco/knightingale-blue.png";
import knightingale_white from "../../img/ProdutEco/knightingale-white.png";
import knightingale_dark from "../../img/ProdutEco/knightingale-dark.png";
import knightingale_logo from "../../img/ProdutEco/knightingale-logo.png";
import knightingale_logo_pro from "../../img/ProdutEco/knightingale-logo-pro.png";
import knightingale_logo_xr from "../../img/ProdutEco/knightingale-logo-xr.png";
// import calorie_counter from "../../img/ProdutEco/calorie-counter.png";
// import care_remainder from "../../img/ProdutEco/care-reminder.png";

import "../Frequently/Frequently.css";

const Frequently = () => {
    return (
        <div className="kn-section-wrpper">
            <div className="kn-section-container kn-section-container-ProdutEco ">
                <div className="kn-section-inner kn-section-inner-Frequently">
                    <div className="kn-section-header">
                        <div className="Frequently-title">Frequently Asked Questions</div>
                        <div className="kn-section-description"></div>
                    </div>

                    <div className="Frequently-wrapper">
                        <div className="Frequently-container">
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                        What is Knightingale?
                                    </div>
                                    <div className="Frequently-item-details">
                                    Knightingale is your go-to AI Health Companion, crafted to deliver personalized wellness advice and support. Leveraging the latest in AI and natural language processing, it engages you in enriching conversations to offer health recommendations perfectly suited to your needs.
                                    </div>
                                </div>
                            </div>
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    How does Knightingale operate?  
                                    </div>
                                    <div className="Frequently-item-details">
                                    Knightingale connects through instant messaging and voice, allowing you to express your health queries, ambitions, and preferences. Utilizing advanced algorithms, it interprets this data to provide you with bespoke advice, resources, and support, making it your very own Health Companion.
                                    </div>
                                </div>
                            </div>
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    What type of health advice does Knightingale give?  
                                    </div>
                                    <div className="Frequently-item-details">
                                    Knightingale, your AI Health Companion, offers guidance across health and wellness domains such as diet, physical activity, stress relief, sleep quality, and mindfulness. It tailors its advice to cater to your personal health objectives and requirements, ensuring the support is as unique as you are.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Frequently-container">
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    How can Knightingale enhance corporate wellness programs?  
                                    </div>
                                    <div className="Frequently-item-details">
                                    Knightingale can be integrated into corporate wellness schemes to uplift employee health and morale. It brings to the table customized wellness solutions like challenges, health evaluations, educational material, and virtual support circles, all designed by a research team of doctors and engineers, making it a smart addition to any workplace.
                                    </div>
                                </div>
                            </div>
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    Who created Knightingale?    
                                    </div>
                                    <div className="Frequently-item-details">
                                    Knightingale was developed by a research team of doctors and engineers, led by experienced leaders in healthcare and technology. This collaboration aimed to create a reliable and empathetic Health Companion, focusing on offering practical health advice and support.

                                    </div>
                                </div>
                            </div>
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    Can Knightingale be tailored for specific business needs?   
                                    </div>
                                    <div className="Frequently-item-details">
                                    Yes, Knightingale's platform is highly adaptable to fit the specific wellness goals and branding of any organization. Whether it's integrating tailored health initiatives, incorporating your branding, or syncing with existing wellness infrastructures, Knightingale can be customized to meet diverse corporate demands. Contact us to see how Knightingale can align with your business's unique needs.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Frequently;
