import React from "react";
import footer_graphic from "../../img/footer_graphic.jpg";
// import done from "../../img/BannerBlue/done.png";
// import mobile_video_loop_thumbnail from "../../img/BannerBlue/mobile_video_loop_thumbnail.jpg";
import footer_youga_video from "../../video/footer_youga_video.mp4";

import "../BannerBlue/BannerBlue.css";

const BannerBlue = () => {
    const handleClick = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSejhBcGyhl3O7adKyeOxXgPKKp01oqeJ2nVaknv5T5KemlSwQ/viewform?usp=sf_link', '_blank');
    };
    
    return (
        <div className="kn-section-wrpper  kn-section-wrpper-BannerBlue">
            <div className="kn-section-container kn-section-container-BannerBlue ">
                <div className="kn-section-inner kn-section-inner-BannerBlue">
                    <div className="kn-section-title"></div>
                    <div className="bannerblue-wrapper">
                        <div className="bannerblue-container">
                            <div className="bannerblue-inner">
                                <div className="bannerblue-img">
                                {/* footer_youga_video */}
                                {/* <video width="100%" height="100%" playsinline loop autoPlay muted  >
                <source src={footer_youga_video} type="video/mp4" />
                Your browser does not support the video tag.
              </video> */}
                                    <img src={footer_graphic} alt="knightingale_bannerblue" />
                                </div>
                                <div className="bannerblue-details">
                                    <div className="bannerblue-details-inner">
                                        <div className="bannerblue-details-inner-title">
                                        Ready to revolutionize wellness with a customized or cobranded Knightingale for your organization, brand, or business? 
                                        </div>
                                        <div className="bannerblue-details-inner-description">
                                            
                                        </div>
                                        <div className="bannerblue-details-inner-btn">
                                            <div className="bannerblue-details-inner-btn-wrapper" onClick={handleClick}>
                                            Become a Partner
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerBlue;
