import React from "react";
import Personalized from "../../img/humanized/personalized.png";
import healthExpert from "../../img/humanized/health-expert.png";
import multimodal from "../../img/humanized/multimodal.png";
import empathic from "../../img/humanized/empathic.png";

import "../Humanized/Humanized.css";

const Humanized = () => {
  return (
    <div className="humanized-wrpper">
      <div className="humanized-container">
        <div className="humanized-inner">
          <div className="humanized-title">The Humanized AI</div>
          <div className="humanized-text">
            Knightingale combines scientific wellness expertise with empathetic AI to offer tailored human-like guidance.
          </div>
          <div className="humanized-wrapper">
            <div className="humanized-detail-container">
              <div className="humanized-detail-inner-wrapper">
                <div className="humanized-detail-inner">
                  <div className="humanized-detail">
                    <div className="humanized-img">
                      <img src={healthExpert} alt="" />
                    </div>
                    <div className="humanized-detaile-title">
                      Intelligent
                    </div>
                    <div className="humanized-details-text">
                      Deep know-how in health and wellness related problem solving.

                    </div>
                  </div>
                </div>
                <div className="humanized-detail-inner">
                  <div className="humanized-detail">
                    <div className="humanized-img">
                      <img src={Personalized} alt="" />
                    </div>
                    <div className="humanized-detaile-title">Adaptive</div>
                    <div className="humanized-details-text">
                      Integrates health data sources, learns about you and adapts.
                    </div>
                  </div>
                </div>
              </div>
              <div className="humanized-detail-inner-wrapper">
                <div className="humanized-detail-inner">
                  <div className="humanized-detail">
                    <div className="humanized-img">
                      <img src={multimodal} alt="" />
                    </div>
                    <div className="humanized-detaile-title">Empathetic</div>
                    <div className="humanized-details-text">
                      Provides caring guidance, motivation and accountability.
                    </div>
                  </div>
                </div>
                <div className="humanized-detail-inner">
                  <div className="humanized-detail">
                    <div className="humanized-img">
                      <img src={empathic} alt="" />
                    </div>
                    <div className="humanized-detaile-title">Confidential</div>
                    <div className="humanized-details-text">
                      Enforces strict measures to keep your information safe and secure.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Humanized;
